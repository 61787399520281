var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v1 px-2"
  }, [_c('div', {
    staticClass: "auth-inner py-2"
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('vuexy-logo'), _c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v(" Vuexy ")])], 1), _c('b-card-title', {
    staticClass: "mb-1"
  }, [_vm._v(" Forgot Password? 🔒 ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Enter your email and we'll send you instructions to reset your password ")]), _c('validation-observer', {
    ref: "simpleRules"
  }, [_c('b-form', {
    staticClass: "auth-forgot-password-form mt-2",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "forgot-password-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "forgot-password-email",
            "state": errors.length > 0 ? false : null,
            "name": "forgot-password-email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.userEmail,
            callback: function callback($$v) {
              _vm.userEmail = $$v;
            },
            expression: "userEmail"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "type": "submit"
    }
  }, [_vm._v(" Send reset link ")])], 1)], 1), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('b-link', {
    attrs: {
      "to": {
        name: 'auth-login-v1'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ChevronLeftIcon"
    }
  }), _vm._v(" Back to login ")], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }