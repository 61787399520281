var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-img', {
    attrs: {
      "src": require('@/assets/images/logo/logov.png'),
      "width": "150"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }