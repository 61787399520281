<template>
  <b-img :src="require('@/assets/images/logo/logov.png')" width="150" />
</template>

<script>
import { BImg } from "bootstrap-vue";

export default {
  components: {
    BImg,
  },
};
</script>


<style>
</style>